import 'bootstrap/dist/css/bootstrap.min.css';
import 'draft-js/dist/Draft.css';

import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Editor, EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import ReactGA from "react-ga4";

ReactGA.initialize('G-KPJN5H3SWV');

const DEFAULT_CONTENT = `
<p>Acute insomnia refers to short-term sleeping difficulties that generally last no more than a few weeks.</p>
<p>Chronic insomnia refers to insomnia that affects your sleep for 3 or more times each day on a regular basis, typically for a period of 3 months or longer.</p>
<p>Onset insomnia describes difficulty falling asleep. Trouble getting to sleep might happen as a result of caffeine use, mental health symptoms, or other common insomnia triggers, but it can also develop with other sleep disorders.</p>
<p>Giraffes have four legs and two heads.</p>
<p>The Great Wall of China is visible from space with the naked eye.</p>
<p>The capital of France is Berlin.</p>
<p>The Earth is flat.</p>
<p>Water boils at a lower temperature at higher altitudes.</p>
<p>Chameleons can change color at will.</p>
<p>The human body is made up of 80% water.</p>
<p>The sun orbits around the earth.</p>
<p>The average lifespan of a housefly is 4 years.</p>
<p>The color of the sky is caused by the reflection of the ocean.</p>`;

const colors = [
  "rgba(251, 57, 57, 0.5)",
  "rgba(253, 86, 33, 0.5)",
  "rgba(250, 112, 0, 0.5)",
  "rgba(242, 136, 0, 0.5)",
  "rgba(230, 159, 0, 0.5)",
  "rgba(214, 181, 0, 0.5)",
  "rgba(193, 201, 0, 0.5)",
  "rgba(167, 220, 0, 0.5)",
  "rgba(133, 238, 30, 0.5)",
  "rgba(76, 255, 84, 0.5)"
];

const subs = {};
let results = {};

const Decorated = ({ children }) => {
  const DEFAULT_BACKGROUND = 'linear-gradient(90deg, #fffff6, #f7f7df)';

  const [background, setBackground] = useState(DEFAULT_BACKGROUND);
  const [explanation, setExplanation] = useState('');
  const [loading, setLoading] = useState(true);

  const update = () => {
    // let text = '';

    // children.forEach((child) => {
    //   text += child.props.text;
    // });

    let text = children[0].props.block.getText();
    
    let result = results[text];

    if (result) {
      setLoading(false);
      setBackground(result.color);
      setExplanation(result.explanation);
    } else {
      setLoading(true);
      setBackground(DEFAULT_BACKGROUND);
      setExplanation('');
    }
  };
  
  subs[children[0].props.block.getKey()] = () => {
    update();
  };

  useEffect(update, [children]);

  return <span className="bg-size-400" title={explanation} style={{ animation: loading ? 'gradient 3s linear infinite': '', background: background }}>{children}</span>;
};

const createDecorator = () => new CompositeDecorator([{
  strategy: handleStrategy,
  component: Decorated
}]);

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// const debounces = {};

const notifySubs = (contentBlockKey) => {
  subs[contentBlockKey] && subs[contentBlockKey]();
  subs['all'] && subs['all']();
}

function handleStrategy(contentBlock, callback) {
  const text = contentBlock.getText()

  callback(0, text.length);
}

const fetchResult = (text) => {
  return fetch('https://koala.sh/api/factcheck/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Foo': 'bar',
      'Version': '3'
    },
    body: JSON.stringify({
      'text': text
    })
  }).then(response => {
    return response.json();
  }).then(json => {
    // console.log('result', text, json);

    let truth = Math.round(json.truth * 10);
    let raw = json.explanation.split('>>>');
    let explanation = raw.shift();
    let recommended = raw.join('>>>');

    return {
      truth: truth,
      color: colors[Math.max(truth - 1, 0)],
      explanation: explanation,
      recommended: recommended
    };
  })
  .catch(err => {
    console.log('catch', err);

    return {
      color: '#fff',
      explanation: 'Error.'
    };
  });
}

const contentUpdateDebounce = debounce((blocks) => {
  blocks.forEach((block, idx) => {
    let text = block.getText();
    let contentBlockKey = block.getKey();

    if (text.length <= 3) {
      return;
    }

    if (results[text]) {
      return;
    }

    if (allResults[text]) {
      results[text] = allResults[text];
      notifySubs(contentBlockKey);
      return;
    }

    let cache = cached[text];
    let promise;

    if (cache) {
      promise = cache;
    } else {
      promise = fetchResult(text);
      cached[text] = promise;
    }

    promise.then((result) => {
      result.idx = idx;
      results[text] = result;
      allResults[text] = result;
      notifySubs(contentBlockKey);
      console.log('fetched', text, result);
    });
  });
}, 500);

const cached = {};
const allResults = {};

function Chat() {
  const [inputHistory, setInputHistory] = useState([]);
  const [outputHistory, setOutputHistory] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [pendingInput, setPendingInput] = useState("");

  const abortController = useRef(new AbortController());

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [loading]);

  useEffect(() => {
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  }, [input, inputRef]);

  const cancel = () => {
    console.log('cancel');
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const handleSubmit = (e) => {
    const ENTER_KEY = 13;

    if (e.keyCode !== ENTER_KEY || e.shiftKey) {
      return;
    }

    e.preventDefault();

    setLoading(true);
    setPendingInput(input);
    setInput("");

    return fetch('https://koala.sh/api/gpt/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Foo': 'bar',
      },
      body: JSON.stringify({
        'input': input,
        'inputHistory': inputHistory,
        'outputHistory': outputHistory
      }),
      signal: abortController.current.signal
    }).then(response => {
      return response.json();
    }).then(json => {
      setLoading(false);
      setInputHistory([...inputHistory, input]);
      setOutputHistory([...outputHistory, json.output]);
    }).catch((err) => {
      setLoading(false);

      if (err.name !== 'AbortError') {
        setInputHistory([...inputHistory, input]);
        setOutputHistory([...outputHistory, 'API ERROR :( Try refreshing the page.']);
        console.error(err);
      }
    })
  };

  return (
    <div className="terminal-container">
      <form style={{maxWidth: '100%', width: '800px', margin: '0 auto'}}>
        {/* <div className="terminal-input" style={{color: '#efefef'}}>
          <p>Welcome to koala.sh</p>
          <p>
            /\__/\<br/>
            ( o   o )<br/>
            (  =^=  )<br/>
            (")___(")
          </p>
          <p>Enter your input below</p>
        </div> */}
        {inputHistory.map((entry, idx) => (
          // <div className="terminal-input" key={idx}>
          //   <p>{entry}</p>
          //   <p style={{color: '#efefef'}}>{outputHistory[index]}</p>
          // </div>
          <div key={idx} className={"card mb-4"}>
            <div className="card-body">
              <h6 className="card-subtitle mb-2">{entry}</h6>
              <p className="card-text">
                {outputHistory[idx]}
              </p>
            </div>
          </div>
        ))}
        {loading && (
          <div className="card mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">{pendingInput}</h6>
              <p className="card-text">Thinking</p>
              <Button variant="outline-danger" onClick={(e) => { cancel() }}>Cancel</Button>
            </div>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <textarea
            rows="1"
            disabled={loading}
            ref={inputRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleSubmit}
            className="terminal-text-input"
          />
        </div>
      </form>
    </div>
  );
}

function App() {
  let [display, setDisplay] = useState('fact');

  const [editorState, setEditorState] = React.useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(DEFAULT_CONTENT)
      ), 
      createDecorator()
    ),
  );

  useEffect(() => {
    let blocks = editorState.getCurrentContent().getBlocksAsArray();

    contentUpdateDebounce(blocks);

    let filteredResults = {};

    blocks.forEach((block) => {
      let text = block.getText();

      if (results[text]) {
        filteredResults[text] = results[text];
      }
    });

    results = filteredResults;

    notifySubs();
  }, [editorState]);

  const editor = React.useRef(null);

  const [errors, setErrors] = useState([]);

  subs['all'] = () => {
    let newErrors = [];

    for (const [text, result] of Object.entries(results)) {
      newErrors.push({
        text: text,
        ...result
      });
    }
    
    setErrors(newErrors);
  };

  let top = (
    <div style={{backgroundColor: '#222', color: '#efefef', padding: '0'}}>
      <a style={{display: 'inline-block', padding: '6px 12px', textDecoration: 'none', color: '#efefef', fontWeight: display === 'fact' ? 'bold' : 'inherit'}} href="" onClick={(e) => { e.preventDefault(); setDisplay('fact')}}>Fact Check</a>
      <a style={{display: 'inline-block', padding: '6px 12px', textDecoration: 'none', color: '#efefef', fontWeight: display === 'chat' ? 'bold' : 'inherit'}} href="" onClick={(e) => { e.preventDefault(); setDisplay('chat')}}>Chat</a>
    </div>
  );

  if (display === 'unavailable') {
    return 'Unavailable at the moment';
  }

  return (
    <>
      {top}
      <div style={{display: display === 'chat' ? 'block' : 'none'}}>
        <Chat />
      </div>
      <Container className="main-container" style={{display: display === 'fact' ? 'block' : 'none'}}>
        <Row>
          <Col className="editor-container">
            <div></div>
            <Editor
              ref={editor}
              editorState={editorState}
              onChange={editorState => setEditorState(editorState)}
            />
          </Col>
          <Col>
            {[...errors].sort((a, b) => {
              if (a.truth === b.truth) {
                return a.idx - b.idx;
              } else {
                return a.truth - b.truth;
              }
            }).map((error, idx) => (
              <div key={idx} className={"card mb-4 " + (error.truth < 3 ? 'border-danger' : error.truth <= 5 ? 'border-warning' : '')}>
                <div className="card-body">
                  <h6 className="card-subtitle mb-2">{error.truth * 10}% Accuracy Rating</h6>
                  <p className="card-text mb-0">
                    <strong>Your Text:</strong> {error.text}
                  </p>
                  <p className="card-text mb-0">
                    <strong>Explanation:</strong> {error.explanation}
                  </p>
                  <p className="card-text">
                    <strong>Recommended:</strong> {error.recommended}
                  </p>
                </div>
              </div>
            ))}
          </Col>  
        </Row>
      </Container>
    </>
  );
}

export default App;
